'use client';

import { IconArrowRight } from '@/components/icons';
import { useSplitAndHeap } from '@/hooks/useSplitAndHeap';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { evaluateBannerDisplay } from './evaluateBannerDisplay';
import cn from 'classnames';

export interface TopBannerProps {
	testId?: string;
}

export interface TopBannerWithLinkConfig {
	'badge-text': string;
	'link-text': string;
	'link-href': string;
	'pages-whitelist': string[];
	'pages-blacklist': string[];
}

const TopBanner = (props: TopBannerProps) => {
	const { testId = 'TopBanner' } = props;
	// To render, or not to render: that is the question: Whether 'tis nobler in
	// the code to endure The props and states of unpredictable behavior, Or to
	// take action against a sea of errors, And by refactoring, end them?
	const [show, setShow] = useState(false);
	// Get the current pathname
	const pathname = usePathname();
	// The split test that controls the banner
	const { TopBannerWithLink } = useSplitAndHeap('TopBannerWithLink');
	// Parse the split test config and avoid re-parsing it on every render
	const splitConfig = useMemo(
		() =>
			JSON.parse(TopBannerWithLink?.config || '{}') as TopBannerWithLinkConfig,
		[TopBannerWithLink?.config],
	);

	// Ensure we re-evaluate the banner display when the pathname or splitConfig changes.
	useEffect(() => {
		setShow(evaluateBannerDisplay(splitConfig, pathname));
	}, [pathname, splitConfig]);

	const {
		'badge-text': badgeText = 'NEW',
		'link-href': linkHref = '/replace/',
		'link-text': linkText = 'Now offering flexible monthly payments',
	} = splitConfig;

	const determineActiveLink = (href: string) => {
		return pathname?.startsWith(href);
	};

	const links = [
		{
			href: '/about-us/',
			text: 'About us',
			isActive: determineActiveLink('/about-us/'),
		},
	];

	return (
		<div
			data-testid={testId}
			className={cn('bg-slate-100 px-4 py-2', { 'hidden lg:block': !show })}
		>
			<div className="max-w-7xl lg:mx-auto lg:flex lg:justify-between">
				<div className="flex items-center gap-2">
					{show && (
						<>
							{badgeText.length > 0 && (
								<div
									className={cn(
										'inline-flex h-fit place-items-center rounded-sm bg-black p-1 lg:rounded lg:px-1.5 lg:py-1',
									)}
								>
									<span
										className={cn(
											'text-xxs font-medium leading-none tracking-normal text-white lg:text-xs',
										)}
									>
										{badgeText}
									</span>
								</div>
							)}
							{linkHref.length > 0 ? (
								<Link
									href={linkHref}
									className="flex items-center gap-0.5 lg:gap-1"
								>
									<span className="text-sm font-medium text-slate-500 lg:text-base">
										{linkText}
									</span>
									<IconArrowRight className="inline-block h-5 w-auto fill-slate-500 lg:h-6" />
								</Link>
							) : (
								<div className="flex items-center gap-0.5 lg:gap-1">
									<span className="text-sm font-medium text-slate-500 lg:text-base">
										{linkText}
									</span>
								</div>
							)}
						</>
					)}
				</div>
				<div className="hidden lg:block">
					<ul className="flex gap-6 pr-7 text-base font-medium text-slate-500">
						{links.map((link) => (
							<TopBannerLink key={link.href} {...link} />
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

const TopBannerLink = (props: {
	href: string;
	text: string;
	isActive: boolean;
}) => (
	<li>
		<Link
			href={props.href}
			className={cn('hover:underline', {
				'text-black': props.isActive,
			})}
		>
			{props.text}
		</Link>
	</li>
);

export default TopBanner;
